import axios from 'axios'

/**
 * Axios instance for making HTTP requests.
 * baseURL: The base URL for all requests made with this instance.
 */
const dashAxios = axios.create({
  //baseURL: 'https://qaapisv.grupodefensa.cl:10443',
  baseURL: 'https://apisucursalvirtual.grupodefensa.cl:11443',
  //baseURL: 'http://192.168.20.74:4040',
})

/**
 * Performs a login query by making a POST request to the specified URL with user data.
 * @param {string} url - The URL to make the request to.
 * @param {object} user - The user data to send in the request body.
 * @returns {Promise} A promise that resolves to the response from the server.
 */
export const queryLogin = async (url, user) => {
  const response = await dashAxios.post(url, user)
  return response
}

/**
 * Performs a query to retrieve customer quotas by making a POST request to the specified URL.
 * @param {string} url - The URL to make the request to.
 * @param {string} token - The session token to include in the request headers.
 * @returns {Promise} A promise that resolves to the response from the server.
 */
export const cuotasClienteQuery = async (url, token) => {
  const response = await dashAxios.post( url, {}, { headers: { Authorization: `Bearer ${token}` }, } )
  return response
}

/**
 * Performs a query to check the schedule by making a POST request to the specified URL with data and session token.
 * @param {string} url - The URL to make the request to.
 * @param {object} data - The data to send in the request body.
 * @param {string} token - The session token to include in the request headers.
 * @returns {Promise} A promise that resolves to the response from the server.
 */
export const checkScheduleQuery = async (url, data, token) => {
  console.log(data)
  const response = await dashAxios.post(url, data, { headers: { Authorization: `Bearer ${token}` }, })

  return response
}

/**
 * Performs a query to reset the password by making a PUT request to the specified URL with data and session token.
 * @param {string} url - The URL to make the request to.
 * @param {object} data - The data to send in the request body.
 * @param {string} token - The session token to include in the request headers.
 * @returns {Promise} A promise that resolves to the response from the server.
 */
export const resetPasswordQuery = async (url, data, token) => {
  const response = await dashAxios.put(url, data, { headers: { Authorization: `Bearer ${token}` }, })
  return response
}

/**
 * Performs a query to reset the password by making a PUT request to the specified URL with data and session token.
 * @param {string} url - The URL to make the request to.
 * @param {object} data - The data to send in the request body.
 * @param {string} token - The session token to include in the request headers.
 * @returns {Promise} A promise that resolves to the response from the server.
 */
export const resetPasswordOption = async (url, data, token) => {
  const response = await dashAxios.post(url, data, { headers: { Authorization: `Bearer ${token}` }, })
  return response
}

/**
 * Performs a query to confirm an appointment by making a POST request to the specified URL with data and session token.
 * @param {string} url - The URL to make the request to.
 * @param {object} data - The data to send in the request body.
 * @param {string} token - The session token to include in the request headers.
 * @returns {Promise} A promise that resolves to the response from the server.
 */
export const confirmAppointmentQuery = async (url, data, token) => {
  const response = await dashAxios.post(url, data, { headers: { Authorization: `Bearer ${token}` }, })
  return response
}

/**
 * Performs a query to confirm an appointment by making a POST request to the specified URL with data and session token.
 * @param {string} url - The URL to make the request to.
 * @param {object} data - The data to send in the request body.
 * @param {string} token - The session token to include in the request headers.
 * @returns {Promise} A promise that resolves to the response from the server.
 */
export const sucursalAppointmentQuery = async (url,token) => {
  const response = await dashAxios.post(url, null, { headers: { Authorization: `Bearer ${token}` }, })
  return response
}

/**
 * Performs a query to cancel an appointment by making a DELETE request to the specified URL with session token.
 * @param {string} url - The URL to make the request to.
 * @param {string} token - The session token to include in the request headers.
 * @returns {Promise} A promise that resolves to the response from the server.
 */
export const cancelAppointmentQuery = async (url, token) => {
  const response = await dashAxios.delete(url, { headers: { Authorization: `Bearer ${token}` }, })
  return response
}


/**
 * Performs a query to update a user profile by making a PUT request to the specified URL with data and session token.
 * @param {string} url - The URL to make the request to.
 * @param {object} data - The data to send in the request body.
 * @param {string} token - The session token to include in the request headers.
 * @returns {Promise} A promise that resolves to the response from the server.
 */
export const getProfileQuery = async (url,token) => {
  const response = await dashAxios.post(url, {}, { headers: { Authorization: `Bearer ${token}` }, })
  return response
}

/**
 * Performs a query to update a user profile by making a PUT request to the specified URL with data and session token.
 * @param {string} url - The URL to make the request to.
 * @param {object} data - The data to send in the request body.
 * @param {string} token - The session token to include in the request headers.
 * @returns {Promise} A promise that resolves to the response from the server.
 */
export const postNoDataQuery = async (url,token) => {
  const response = await dashAxios.post(url, null, { headers: { Authorization: `Bearer ${token}` }, })
  return response
}



/**
 * Performs a query to update a user profile by making a PUT request to the specified URL with data and session token.
 * @param {string} url - The URL to make the request to.
 * @param {object} data - The data to send in the request body.
 * @param {string} token - The session token to include in the request headers.
 * @returns {Promise} A promise that resolves to the response from the server.
 */
export const updateProfileQuery = async (url, data, token) => {
  const response = await dashAxios.put(url, data, { headers: { Authorization: `Bearer ${token}` }, })
  return response
}

export const getBenefitsQuery = async (url, token) => {
  const response = await dashAxios.post(
    url,
    {},
    { headers: { Authorization: `Bearer ${token}` }, }
  )
  return response
}

export const getMovementsQuery = async (url, token) => {
  const response = await dashAxios.post(
    url,
    {},
    { headers: { Authorization: `Bearer ${token}` }, }
  )
  
  return response
}
export default dashAxios
