import React, { useEffect, useState } from 'react'
import { Text, View, StyleSheet,TouchableOpacity, Button, Modal,ScrollView, TouchableHighlight  } from 'react-native'
import { movementsStyles } from './movements.styles'
import HeaderPageTitle from '../../common/HeaderPageTitle'
import { generalStyles } from '../../styles/general.styles'
import { getMovementsQuery } from '../../services/axios'
import Loading from '../../common/Loading'
import { withRouter } from 'react-router-dom'
import { notify } from '../../utils/notify'
import { styles } from '../../styles/modalMovements.styles'
import isMobile from '../../utils/dimensions'
import { format } from 'date-fns'

const Movements = ({ history }) => {

  const [data, setData] = useState()
  const [modalVisible, setModalVisible] = useState(false)
  const [modalVisiblePjud, setModalVisiblePjud] = useState(false)
  const [currentMessages, setCurrentMessages] = useState([])
  const [currentMessagesPjud, setCurrentMessagesPjud] = useState([])
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [loading, setLoading] = useState(false);

  if(localStorage.getItem('token')==null){
    localStorage.removeItem('token')
    history.push('/iniciar-sesion')
    return
  }

  useEffect(()=>{ 
    if(windowHeight>500){
     setWindowHeight(500)
    }else{
      setWindowHeight(windowHeight-100)
    }

    if(windowWidth>800){
      setWindowWidth("800px")
    }else{
      setWindowWidth("90%")
    }
    
  },[])

  function formatDate(inputDate) {
    const date = new Date(inputDate)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')  // Enero es 0, así que sumamos 1
    const year = date.getFullYear()
  
    return `${day}-${month}-${year}`
  }

  const TableRow = ({ data, header = false }) => (
    <View style={styles.row}>
      <Text
        style={[
          {marginBottom: '10px'},
          styles.cell,
          styles.cellFechaCreacion,
          header && styles.header, // Esto debería estar al final
        ]}
      >
        {header ? data.fechaActualizacion : formatDate(data.fechaActualizacion)}
      </Text>
      {/*<Text
        style={[
          styles.cell,
          styles.cellFechaCreacion,
          header && styles.header, // Esto debería estar al final
        ]}
      >
        {data.fechaTermino}
      </Text>
      <Text
        style={[
          styles.cell,
          styles.cellEstado,
          header && styles.header, // Esto debería estar al final
        ]}
      >
        {data.estado}
      </Text>}*/}
      
      <Text
        style={[
          {marginBottom: '10px'},
          styles.cell,
          styles.cellMensaje,
          header && styles.header, // Esto debería estar al final
        ]}
      >
        {data.mensaje}
      </Text>
    </View>
  )
  const TableRowPjud = ({ data, header = false }) => (
    <View style={styles.row}>
      <Text
        style={[
          {marginBottom: '10px'},
          styles.cellPjud,
          styles.cellFechaCreacion,
          header && styles.headerPjud,
        ]}
      >
        {header ? data.fecha_doc : formatDate(data.fecha_doc)}
      </Text>
      <Text
        style={[
          {marginBottom: '10px'},
          styles.cellPjud,
          styles.cellEstado,
          header && styles.headerPjud,
        ]}
      >
        {data.tramite}
      </Text>
      <Text
        style={[
          {marginBottom: '10px'},
          styles.cellPjud,
          styles.cellMensaje,
          header && styles.headerPjud,
        ]}
      >
        {data.desc_tramite}
      </Text>
      {header ? (
        <Text
          style={[
            {marginBottom: '10px'},
            styles.cellPjud,
            styles.cellMensaje,
            styles.headerPjud,
          ]}
        >
          {data.url_doc}
        </Text>
      ) : (
        <a 
          href={data.url_doc} 
          target="_blank" 
          rel="noopener noreferrer" 
          style={styles.link}
        >
          Descargar Documento
        </a>
      )}
    </View>
  )

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      history.push('/iniciar-sesion') // Ajusta la ruta de inicio de sesión según tu configuración de navegación
    }
  }, [])

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      setLoading(true)
      const response = await getMovementsQuery(
        '/Client/InformacionCausas',
        localStorage.getItem('token')
      )
      setLoading(false)
      setData(response.data)
    } catch (error) {
      setLoading(false)
      if(error.response.status==401){
        localStorage.removeItem('token')
        history.push('/iniciar-sesion')
      }else{
        const err = error.response
        notify('error', err)
      }
    }
  }

  

  return (
    <View style={(isMobile()?generalStyles.contentContainerResponse:generalStyles.contentContainer)} >
      <HeaderPageTitle title="REVISA EL ESTADO DE TU CAUSA Y ACTUALIZACIONES EN EL PODER JUDICIAL" />

      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible)
        }}
      >
        <View style={styles.centeredView}>
          <View style={ { ...{maxWidth:windowWidth}, ...styles.modalView }}>
            <ScrollView style={{maxHeight: windowHeight+"px"}}>
              {currentMessages.length === 0 ? (
                <Text style={styles.noDataText}> Estamos trabajando en tu causa <br></br></Text>
              ) : (
              <>  
             
                  <TableRow 
                    data={{ fechaActualizacion: "Fecha Actualización", mensaje: "Mensaje" }}
                    header={true}
                  />
                  {currentMessages.map((msg, index) => (
                    <TableRow data={msg} key={index} />
                  ))}
              </>
              )}
            </ScrollView>
            <View style={{width:'75%', marginTop: 10}}>
              <Button title="Cerrar" onPress={() => setModalVisible(false)} />
              <View style={{marginBottom:5}}></View>
              <Button title="Habla con un abogado" onPress={() => history.push('/agendar-hora')} />
            </View>
          </View>
        </View>
      </Modal>

      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisiblePjud}
        onRequestClose={() => {
          setModalVisiblePjud(!modalVisiblePjud)
        }}
      >
        <View style={styles.centeredView}>
          <View style={ { ...{maxWidth:windowWidth}, ...styles.modalView }}>
            <ScrollView style={{maxHeight: windowHeight+"px"}}>
              {currentMessagesPjud.length === 0 ? (
                <Text style={styles.noDataText}> Sin Información desde el PJUD <br></br></Text>
              ) : (
              <>
                  <TableRowPjud
                    data={{ fecha_doc: "Fecha de Creación", tramite: "Tramite", desc_tramite: "Descripción" , url_doc: "Documento" }}
                    header={true}
                  />
                  {currentMessagesPjud.map((msg, index) => (
                    <TableRowPjud data={msg} key={index} />
                  ))}
              </>
              )}
            </ScrollView>
            <Button style={{marginTop: '15px'}} title="Cerrar" onPress={() => setModalVisiblePjud(false)} />
          </View>
        </View>
      </Modal>
      
      {isMobile()?
        <>
          {data ? (
            data.map((item, index) => (
              <View style={movementsStyles.rowResponsive} key={index}>
                <Text style={{ width: '100%' }}>
                  Rol:{item.rol}
                </Text>
                  {/*<Text style={[movementsStyles.cell, { width: 200 }]}>
                    {item.titulo}
                  </Text>*/}
                <Text style={ { width: '100%', marginTop: '5px', marginBottom: '15px' }}>
                Tribunal:{item.tribunal}
                </Text>
                <TouchableHighlight 
                    style ={{
                        height: 40,
                        width:'75%',
                    }}>
                <Button
                  title="Ver Movimientos" 
                  onPress={() => {
                    if(item.activo){
                      let objetosActualizados = item.tablaMensajes.map(objeto => {
                        // Obtenemos la fecha más reciente entre fechaCreacion y fechaTermino
                        const fechaCreacion = new Date(objeto.fechaCreacion)
                        const fechaTermino = objeto.fechaTermino ? new Date(objeto.fechaTermino) : null
                        const fechaMasReciente = fechaTermino && fechaTermino > fechaCreacion ? fechaTermino : fechaCreacion
                      
                        // Creamos un nuevo objeto con fechaActualizacion en lugar de fechaCreacion y fechaTermino
                        return {
                          estado: objeto.estado,
                          fechaActualizacion: fechaMasReciente.toISOString().split('T')[0], // Formateamos como "año-mes-día"
                          mensaje: objeto.mensaje,
                        }
                      })
                      if(objetosActualizados.length==0)
                        objetosActualizados = item.tablaMovimientos.map(objeto => {
                          // Obtenemos la fecha más reciente entre fechaCreacion y fechaTermino
                          const fechaCreacion = new Date(objeto.fechaCreacion)
                          // Creamos un nuevo objeto con fechaActualizacion en lugar de fechaCreacion y fechaTermino
                          return {
                            estado: objeto.estado,
                            fechaActualizacion: format(fechaCreacion, 'dd-MM-yyyy'),
                            mensaje: objeto.mensaje,
                          }
                        })
                        setCurrentMessages(objetosActualizados)
                        setModalVisible(true)
                    }else{
                      notify('info', 'Para poder hacer seguimiento y revisión de los movimientos de sus casusas, lo invitamos a regularizar sus pagos, comunicándose al email bajas@grupodefensa.cl.')
                    }
                  }} 
                />
              </TouchableHighlight> 

                
                <Text style={[{ width: 10 }]}>
                </Text>
                <TouchableHighlight 
                    style ={{
                        height: 40,
                        width:'75%',
                    }}>
                <Button 
                  color='green'
                  title="Información PJUD" 
                  onPress={() => {
                    if(item.activo){
                      setCurrentMessagesPjud(item.tablaPjud)
                      setModalVisiblePjud(true)
                    }else{
                      notify('info', 'Para poder hacer seguimiento y revisión de los movimientos de sus casusas, lo invitamos a regularizar sus pagos, comunicándose al email bajas@grupodefensa.cl.')
                    }
                  }} 
                />
              </TouchableHighlight> 

                
              </View>
              
              
            ))
          ) : !loading ?? <HeaderPageTitle title="Pronto tenedremos informacion de la gestion de tus causas" />}
          {loading && ( <Loading />)}
        </>
      :
      <>
        <View style={movementsStyles.row}>
          <Text style={[movementsStyles.headerCell, { width: 200 }]}>Rol de su causa</Text>
          <Text style={[movementsStyles.headerCell, { width: 200 }]}>Linea de negocio</Text>
          <Text style={[movementsStyles.headerCell, { width: 200 }]}>Tribunal</Text>
          <Text style={[movementsStyles.headerCell, { width: 100 }]}>Acciones</Text>
        </View>

        {data ? (
          data.map((item, index) => (
            <View style={movementsStyles.row} key={index}>
              <Text style={[movementsStyles.cell, { width: 200 }]}>
                {item.rol}
              </Text>
              <Text style={[movementsStyles.cell, { width: 200 }]}>
                  {item.lineaNegocio}
                </Text>
              <Text style={[movementsStyles.cell, { width: 200 }]}>
                {item.tribunal}
              </Text>
              <View style={[movementsStyles.headerCell, { width: 100 }]}>
                <Button
                  title="Ver Movimientos" 
                  onPress={() => {
                    if(item.activo){
                      let objetosActualizados = item.tablaMensajes.map(objeto => {
                        // Obtenemos la fecha más reciente entre fechaCreacion y fechaTermino
                        const fechaCreacion = new Date(objeto.fechaCreacion)
                        const fechaTermino = objeto.fechaTermino ? new Date(objeto.fechaTermino) : null
                        const fechaMasReciente = fechaTermino && fechaTermino > fechaCreacion ? fechaTermino : fechaCreacion
                      
                        // Creamos un nuevo objeto con fechaActualizacion en lugar de fechaCreacion y fechaTermino
                        return {
                          estado: objeto.estado,
                          fechaActualizacion: fechaMasReciente.toISOString().split('T')[0], // Formateamos como "año-mes-día"
                          mensaje: objeto.mensaje,
                        }
                      })
                      if(objetosActualizados.length==0)
                        objetosActualizados = item.tablaMovimientos.map(objeto => {
                          // Obtenemos la fecha más reciente entre fechaCreacion y fechaTermino
                          const fechaCreacion = new Date(objeto.fechaCreacion)
                          // Creamos un nuevo objeto con fechaActualizacion en lugar de fechaCreacion y fechaTermino
                          return {
                            estado: objeto.estado,
                            fechaActualizacion: format(fechaCreacion, 'dd-MM-yyyy'),
                            mensaje: objeto.mensaje,
                          }
                        })
                        setCurrentMessages(objetosActualizados)
                        setModalVisible(true)
                    }else{
                      notify('info', 'Para poder hacer seguimiento y revisión de los movimientos de sus casusas, lo invitamos a regularizar sus pagos, comunicándose al email bajas@grupodefensa.cl.')
                    }
                  }} 
                />
                <View style={{height:5}}></View>
                <Button title="Información PJUD" color='green'
                  onPress={() => {
                    if(item.activo){
                      setCurrentMessagesPjud(item.tablaPjud)
                      setModalVisiblePjud(true)
                    }else{
                      notify('info', 'Para poder hacer seguimiento y revisión de los movimientos de sus casusas, lo invitamos a regularizar sus pagos, comunicándose al email bajas@grupodefensa.cl.')
                    }
                  }} 
                />
              </View>
            </View>
            
            
          ))
        ) : !loading ?? <HeaderPageTitle title="Pronto tenedremos informacion de la gestion de tus causas" />}
        {loading && ( <Loading />)}
      </>
      }
    </View>
  )
}


const stylesButton = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    paddingVertical: 5,
    paddingHorizontal: 20,
    borderRadius: 5,
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  line: {
    height: 1,
    backgroundColor: 'gray', // Color de la línea
    marginTop: 8, // Espacio superior para separar la línea del contenido
  }
});


export default withRouter(Movements)
