import React, { useState } from 'react'
import { View, Button, TouchableOpacity, Text, Image, StyleSheet, ImageBackground, Modal, ScrollView } from 'react-native'
import { Formik, Field, ErrorMessage } from 'formik'
import { withRouter } from 'react-router-dom' // Importa withRouter
import { loginStyles } from './login.styles'
import { queryLogin } from '../../services/axios'
import { notify } from '../../utils/notify'
import { TextInput } from 'react-native'
import Loading from '../../common/Loading'
import LoadingButton from '../../common/LoadingButton'
import { useEffect } from 'react'
import { styles as stylesModal } from '../../styles/modalMovements.styles'
import isMobile from '../../utils/dimensions'

const CustomInput = ({ field, form, ...props }) => (
  <TextInput
    style={loginStyles.input}
    onChangeText={value => form.setFieldValue(field.name, value)}
    onBlur={() => form.setFieldTouched(field.name)}
    value={field.value}
    {...props}
  />
);
const CustomInputRut = ({ field, form, ...props }) => (
  <TextInput
    style={loginStyles.input}
    onChangeText={value => form.setFieldValue(field.name, handleRutChange(value))}
    onBlur={() => form.setFieldTouched(field.name)}
    value={field.value}
    {...props}
  />
);
const handleRutChange = (texto) => {
  texto = texto.replaceAll("-","")
  return texto.slice(0, -1) + '-' + texto.slice(-1);
};

const Login = ({ history }) => {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const imageback = "https://grupodefensa.cl/content/uploads/gd-banner-web-index-abogados-2023-03-06.jpg"
  const [modalVisible, setModalVisible] = useState(false)

  const handleLogin = async (values) => {
    if(values.rut.length==0 || values.password.length==0){
      notify('error', 'Clave y usuario obligatorio')
      setLoading(false)
      return;
    }
    values.rut = values.rut.replaceAll(".","")

    setLoading(true);
    try {
      const response = await queryLogin('/api/Login', values)
      const { token, primerInicio, data, informacionCliente } = response.data
      setLoading(false)
      localStorage.setItem('token', token)
      localStorage.setItem('informacionCliente',JSON.stringify(informacionCliente))

      if(primerInicio!=null && primerInicio){
        localStorage.setItem('optionsrecover', JSON.stringify({options:data}))      
        history.push('/olvide-mi-contrasena-options')
      }else{
        isMobile() ? history.push('/mis-movimientos'): history.push('/home')
      }
    } catch (error) {
      console.log(error)

      notify('error', 'Clave o usuario incorrecto')
    }finally {
      setLoading(false); // Detiene la carga cuando el inicio de sesión se completa o falla
    }

  }

  const handleForgotPassword = () => {
    history.push('/olvide-mi-contrasena')
  }

  const getLabelStyle = () => {
    return error ? loginStyles.labelError : loginStyles.label;
  };
  useEffect(()=>{
    setModalVisible(true)
  },[])


  return (
    <>
    <Formik initialValues={{ rut: '', password: '' }} onSubmit={handleLogin}>
    {({ handleSubmit }) => (
       <ImageBackground source={imageback} resizeMode="cover" style={styles.image}>
        <View style={loginStyles.container}>
         
            <View style={loginStyles.form}>
                
                <View style={{ alignItems: 'center', width:'100%', height:'100%', maxHeight:250, }}>
                  <Image source={require('../../assets/images/logo_login.png')} style={loginStyles.logoImage} resizeMode="contain"/>
                </View>
                
                <Text style={getLabelStyle()}> RUT </Text>
                <Field
                    component={CustomInputRut}
                    name="rut"
                    placeholder="RUT"
                    autoCapitalize="none"
                />
                <ErrorMessage name="rut" component={Text} style={loginStyles.errorText} />
                <Text style={getLabelStyle()}>
                    Contraseña <Text style={{fontSize:14, color:'#A82F2F'}} ><br/>¿primer ingreso? los 4 primeros digitos del rut</Text>
                </Text>
                <Field
                    component={CustomInput}
                    name="password"
                    placeholder="Contraseña"
                    secureTextEntry={true}
                    autoCapitalize="none"
                />
                <ErrorMessage name="password" component={Text} style={loginStyles.errorText} />
                {error ? (<Text style={loginStyles.errorText}>{error}</Text>):null}
                
                <LoadingButton title="Iniciar sesión" onPress={handleSubmit} isLoading={loading} 
                 activeColor="#4650dd"
                 disabledColor="#d7d9fc"/>
                
                <TouchableOpacity onPress={handleForgotPassword}>
                    <Text style={loginStyles.forgotPasswordText}>
                        ¿Olvidaste tu contraseña?
                    </Text>
                </TouchableOpacity>
                
            </View>
         
        </View>
        </ImageBackground>
    )}
    </Formik>
    <Modal
        animationType="slide"
        transparent={true}
        visible={/* modalVisible */false}
        onRequestClose={() => {
          setModalVisible(!modalVisible)
        }}
      >
        <View style={stylesModal.centeredView}>
          <View style={ { ...{width:'75%', maxWidth: 600}, ...stylesModal.modalView }}>
            <ScrollView style={{maxHeight: "600px"}}>
              <Text style={{fontWeight:'bold', fontSize:18, marginBottom: 10, textAlign:'center', color: '#838383'}}>Bienvenido a tu nueva sucursal virtual </Text>
              <Text style={{fontWeight:'bold', fontSize:15, textAlign:'center', color: '#000',marginBottom: 15}}>Para iniciar por primera siga los siguientes pasos: </Text>
              <Text style={{fontWeight:'italic', fontSize:15, color: '#838383', textAlign:'justify', marginBottom: 5}}>1- Ingrese su Rut y la clave temporal, que son los 4 primeros dígitos del Rut.</Text>
            </ScrollView>
            <Button style={{marginTop: '15px'}} title="Cerrar" onPress={() => setModalVisible(false)} />
          </View>
        </View>
      </Modal>
     </>
  )
}
const styles = StyleSheet.create({
  image: {
    flex: 1,
    justifyContent: 'center',
  }
});

export default withRouter(Login) // Envuelve el componente Login con withRouter
